import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  isDevMode,
} from '@angular/core';
import { Router, provideRouter, withInMemoryScrolling } from '@angular/router';
import { appRoutes } from './app.routes';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { TranslocoHttpLoader } from './transloco-loader';
import { TranslocoService, provideTransloco } from '@jsverse/transloco';
import { authInterceptor } from './common/interceptors/auth/auth.interceptor';
import { ErrorInterceptor } from './common/interceptors/error/error.interceptor';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import * as Sentry from '@sentry/angular';

const DEFAULT_LANGUAGE = 'en';
export function preloadTranslation(transloco: TranslocoService) {
  return function () {
    transloco.setActiveLang(DEFAULT_LANGUAGE);
    return transloco.load(DEFAULT_LANGUAGE);
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      appRoutes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      })
    ),
    provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([authInterceptor])
    ),
    provideTransloco({
      config: {
        availableLangs: ['ar', 'en'],
        defaultLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideAngularSvgIcon(),
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: preloadTranslation,
      deps: [TranslocoService],
      multi: true,
    },
  ],
};
