<span
  class="at-toast"
  [ngClass]="{
    show: showToast,
    'at-bg-success': toastState === 'success',
    'at-bg-failure': toastState === 'error',
    'at-bg-warning': toastState === 'warning',
    'at-bg-information': toastState === 'info',
  }">
  @switch (toastState) {
    @case ('success') {
      <img [src]="successIcon" height="24" width="24" [alt]="'success icon'" />
      <span class="at-ms-2 at-toast-message"> {{ message }} </span>
    }
    @case ('error') {
      <img [src]="errorIcon" height="24" width="24" [alt]="'error icon'" />
      <span class="at-ms-2 at-toast-message"> {{ message }} </span>
    }
    @case ('warning') {
      <img [src]="warningIcon" height="24" width="24" [alt]="'warning icon'" />
      <span class="at-ms-2 at-toast-message"> {{ message }} </span>
    }
    @case ('info') {
      <img [src]="infoIcon" height="24" width="24" [alt]="'info icon'" />
      <span class="at-ms-2 at-toast-message"> {{ message }} </span>
    }
  }
</span>
