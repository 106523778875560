/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastService } from '../../../common/components/toast/toast.service';
import { OffCanvasService } from '../../../common/components/off-canvas/off-canvas.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private toastService: ToastService,
    private offCanvasService: OffCanvasService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error.status === 401 ||
          error.error.message.includes('Unauthenticated') ||
          error.error.message.includes('unauthorized')
        ) {
          this.router.navigate(['/login']);
          localStorage.clear();

          this.offCanvasService.isCartOpen.set({ isOpen: false });
        }

        let errorObj: { code?: unknown; message: unknown; errors?: unknown };
        if (error.error.code || error.error.code !== 422) {
          errorObj = {
            code: error.error.code,
            message: error.error.message,
            errors: error.error.errors,
          };
        } else {
          errorObj = {
            message: error.message,
          };
        }
        console.error('🚀 ~ errorObj:', errorObj);
        this.toastService.show({
          toastState: 'error',
          message: errorObj.message as string,
        });
        return throwError(() => errorObj);
      })
    );
  }
}
