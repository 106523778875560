import { HttpInterceptorFn } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const token = localStorage.getItem('token');

  req = req.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`,
      'X-Authorization': environment.xAuthKey,
      'Accept-Language': environment.defaultLang,
      'Access-Control-Allow-Origin': '*',
    },
  });

  return next(req);
};
