import { Route } from '@angular/router';
import { NotLoggedInAuthGuard } from './common/guards/not-logged-in-auth.guard';
import { AuthGuard } from './common/guards/auth.guard';

export const appRoutes: Route[] = [
  {
    path: 'login',
    canActivate: [NotLoggedInAuthGuard],
    loadComponent: () =>
      import('./pages/auth/login/login.component').then(m => m.LoginComponent),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./pages/dashboard/dashboard.component').then(
        m => m.DashboardComponent
      ),
    children: [
      {
        path: 'add-category',
        loadComponent: () =>
          import(
            './pages/dashboard/category-management/add-category/add-category.component'
          ).then(m => m.AddCategoryComponent),
      },
      {
        path: 'edit-category/:id',
        loadComponent: () =>
          import(
            './pages/dashboard/category-management/add-category/add-category.component'
          ).then(m => m.AddCategoryComponent),
      },
      {
        path: 'view-category/:id',
        loadComponent: () =>
          import(
            './pages/dashboard/category-management/category-details/category-details.component'
          ).then(m => m.CategoryDetailsComponent),
      },
      {
        path: 'category-list',
        loadComponent: () =>
          import(
            './pages/dashboard/category-management/category-list/category-list.component'
          ).then(m => m.CategoryListComponent),
      },
      {
        path: 'product-list',
        loadComponent: () =>
          import(
            './pages/dashboard/product-management/product-list/product-list.component'
          ).then(m => m.ProductListComponent),
      },
      {
        path: 'multi-images-upload',
        loadComponent: () =>
          import(
            './pages/dashboard/category-management/multi-image/multi-image.component'
          ).then(m => m.MultiImageComponent),
      },
      {
        path: 'legal-and-policies-list',
        loadComponent: () =>
          import(
            './pages/dashboard/legal-and-policies-management/legal-and-policies-list/legal-and-policies-list.component'
          ).then(m => m.LegalAndPoliciesListComponent),
      },
      {
        path: 'view-legal-and-policies/:id',
        loadComponent: () =>
          import(
            './pages/dashboard/legal-and-policies-management/legal-and-policies-details/legal-and-policies-details.component'
          ).then(m => m.LegalAndPoliciesDetailsComponent),
      },
      {
        path: 'support-ticket-list',
        loadComponent: () =>
          import(
            './pages/dashboard/support-ticket-management/support-ticket-list/support-ticket-list.component'
          ).then(m => m.SupportTicketListComponent),
      },
      {
        path: 'support-ticket-details/:id',
        loadComponent: () =>
          import(
            './pages/dashboard/support-ticket-management/support-ticket-details/support-ticket-details.component'
          ).then(m => m.SupportTicketDetailsComponent),
      },
      {
        path: 'view-legal-and-policies/:id/logs',
        loadComponent: () =>
          import(
            './pages/dashboard/legal-and-policies-management/legal-and-policies-logs/legal-and-policies-logs.component'
          ).then(m => m.LegalAndPoliciesLogsComponent),
      },
      {
        path: 'customers-list',
        loadComponent: () =>
          import(
            './pages/dashboard/customer-management/customer-list/customer-list.component'
          ).then(m => m.CustomersListComponent),
      },
      {
        path: 'customer-details/:id',
        loadComponent: () =>
          import(
            './pages/dashboard/customer-management/customer-details/customer-details.component'
          ).then(m => m.CustomerDetailsComponent),
      },
      {
        path: 'faqs',
        loadComponent: () =>
          import(
            './pages/dashboard/support-ticket-management/faqs-editor/faqs-editor.component'
          ).then(m => m.FaqsEditorComponent),
      },
      {
        path: 'faqs/:id',
        loadComponent: () =>
          import(
            './pages/dashboard/support-ticket-management/faqs-editor/faqs-editor.component'
          ).then(m => m.FaqsEditorComponent),
      },
      {
        path: 'faqs-list',
        loadComponent: () =>
          import(
            './pages/dashboard/support-ticket-management/faqs-list/faqs-list.component'
          ).then(m => m.FAQsListComponent),
      },
      {
        path: 'faq-details/:id',
        loadComponent: () =>
          import(
            './pages/dashboard/support-ticket-management/faq-view/faq-view.component'
          ).then(m => m.FaqViewComponent),
      },
      {
        path: 'orders-list',
        loadComponent: () =>
          import(
            './pages/dashboard/order-management/orders-list/orders-list.component'
          ).then(m => m.OrdersListComponent),
      },
      {
        path: 'order/:id',
        loadComponent: () =>
          import(
            './pages/dashboard/order-management/order-details/order-details.component'
          ).then(m => m.OrderDetailsComponent),
      },
      {
        path: 'delete-account-list',
        loadComponent: () =>
          import(
            './pages/dashboard/delete-account-management/delete-account-list/delete-account-list.component'
          ).then(m => m.DeleteAccountListComponent),
      },
      {
        path: 'delete-account-details/:id',
        loadComponent: () =>
          import(
            './pages/dashboard/delete-account-management/delete-account-details/delete-account-details.component'
          ).then(m => m.DeleteAccountDetailsComponent),
      },
      { path: '**', redirectTo: 'category-list' },
    ],
  },
  { path: '**', redirectTo: 'dashboard/category-list', pathMatch: 'full' },
];
