import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastComponent } from './common/components/toast/toast.component';
import { ModalComponent } from './common/components/modal/modal.component';

@Component({
  standalone: true,
  imports: [RouterModule, ToastComponent, ModalComponent],
  selector: 'ecommerce-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {}
