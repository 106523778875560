import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ToastService } from '../components/toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private toast: ToastService
  ) {}

  canActivate(): boolean {
    if (localStorage.getItem('token')) {
      return true;
    } else {
      this.router.navigate(['/login']);
      this.toast.show({
        toastState: 'error',
        message: 'You need to login first in order to access this page',
      });
      return false;
    }
  }
}
